import { BigNumberish, Contract } from "fuels";

const DEADLINE = 1000;

export let swayCallGetDeadline = async (
  contract: Contract,
  deadline?: BigNumberish,
) => {
  const blockHeight = await contract.provider?.getBlockNumber();
  const nexDeadline = blockHeight?.add(deadline || DEADLINE);
  return nexDeadline!;
};
