import { useEffect, useState } from "react";
import { Wallet } from "fuels";
import { ExchangeContractAbi__factory } from 'src/contracts'
import { swayCallGetDeadline } from "src/swayCall";

const DAI_ASSET_ID = "0x0d9be25f6bef5c945ce44db64b33da9235fbf1a9f690298698d899ad550abae1"

const SETHER_ASSET_ID =
  "0x1bdeed96ee1e5eca0bd1d7eeeb51d03b0202c1faf764fec1b276ba27d5d61d89"

const CONTRACT_ID =
  "0x004ff2b3b79a67c1d574fa84a52af46cf42dbc1ac0229d13ec7802460ede9118"

const WALLET_SECRET =
  "0xa5f759ba75c142d64cf64295709f127415bbc1abc06f975d21fcc07e4b33ebb7"
// "0x07960a5124cd6e52b595aed727d640aed2e32cc587a34c09f1b48615378df752";

const wallet = Wallet.fromPrivateKey(
  WALLET_SECRET,
  "https://beta-3.fuel.network/graphql"
);

const contract = ExchangeContractAbi__factory.connect(CONTRACT_ID, wallet);

let Page = () => {
  const [poolInfo, setPoolInfo] = useState({
    reserve_x: BigInt(0),
    reserve_y: BigInt(0),
    lp_token_supply: BigInt(0),
  })
  const [deadline, setDeadline] = useState(BigInt(0))

  useEffect(() => {
    (async () => {
      const { value } = await contract.functions.get_pool_info().get();
      let lp_token_supply = BigInt(value.lp_token_supply.toString());
      let reserve_x = BigInt(value.token_reserve1.toString())
      let reserve_y = BigInt(value.token_reserve2.toString())
      setPoolInfo({ lp_token_supply, reserve_x, reserve_y })

      let deadline = BigInt((await swayCallGetDeadline(contract)).toString())
      setDeadline(deadline)

    })()
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <p>pool account id: {CONTRACT_ID}</p>
        <p>deadline blocknumber: {deadline.toString()}</p>
        <p>LP: {poolInfo.lp_token_supply.toString()}</p>
        <p>x: {poolInfo.reserve_x.toString()}</p>
        <p>y: {poolInfo.reserve_y.toString()}</p>

        <button
          onClick={async () => {
            let deadline = BigInt((await swayCallGetDeadline(contract)).toString())
            let result = await contract.functions
              .swap_with_minimum(100, Number(deadline))
              .callParams({
                forward: [
                  100,
                  SETHER_ASSET_ID
                ],
              })
              .txParams({ gasPrice: 1 })
              .call();
            console.log({ result })
          }}
        >
          swap sETH .. DAI
        </button>

        <button
          onClick={async () => {
            let deadline = BigInt((await swayCallGetDeadline(contract)).toString())
            let result = await contract.functions
              .swap_with_minimum(100, Number(deadline))
              .callParams({
                forward: [
                  100,
                  DAI_ASSET_ID,
                ],
              })
              .txParams({ gasPrice: 1 })
              .call();
            console.log({ result })
          }}
        >
          swap DAI .. sETH
        </button>
      </header>
    </div>
  );
}

export default Page;
