import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import "./index.css";

import Home from './pages/Home';
import Counter from './pages/Counter';

let router = createBrowserRouter([
  {
    path: "/",
    Component: Home,
  },
  {
    path: "/counter",
    Component: Counter,
  },
]);

export default function App() {
  return <RouterProvider router={router} fallbackElement={<Fallback />} />;
}

export function Fallback() {
  return <p>Performing initial data load</p>;
}